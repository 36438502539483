import { useJoinedViews as n, useViewId as a } from "@croquet/react";
import { uniqueNamesGenerator as o, adjectives as i, animals as u } from "unique-names-generator";
function d() {
  const { views: s } = n(), t = a();
  return s.map(({ viewId: e, info: r }) => ({
    userId: e,
    info: r,
    isYou: e === t,
    name: o({
      seed: e,
      dictionaries: [i, u],
      length: 2,
      separator: " ",
      style: "capital"
    })
  }));
}
export {
  d as default
};
