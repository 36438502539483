var r = Object.defineProperty;
var d = (h, s, t) => s in h ? r(h, s, { enumerable: !0, configurable: !0, writable: !0, value: t }) : h[s] = t;
var o = (h, s, t) => (d(h, typeof s != "symbol" ? s + "" : s, t), t);
import { ReactModel as g } from "@croquet/react";
class l extends g {
  constructor() {
    super(...arguments);
    o(this, "state");
    o(this, "stateTogether");
  }
  init(t) {
    super.init({ ...t, trackViews: !0 }), this.state = /* @__PURE__ */ new Map(), this.stateTogether = /* @__PURE__ */ new Map(), this.subscribe(this.id, "setState", this.setState), this.subscribe(this.id, "setStateTogether", this.setStateTogether), this.subscribe(this.id, "functionTogether", this.functionTogether);
  }
  setState({ id: t, newValue: e }) {
    e === void 0 ? this.state.delete(t) : this.state.set(t, e), this.publish(t, "updated", {});
  }
  setStateTogether({ id: t, viewId: e, newValue: a }) {
    let i = this.stateTogether.get(t);
    i === void 0 && (i = /* @__PURE__ */ new Map()), a === void 0 ? i.delete(e) : i.set(e, a), this.stateTogether.set(t, i), this.publish(t, "updated", {});
  }
  functionTogether({ rtKey: t, args: e }) {
    this.publish(t, "call", e);
  }
  handleViewExit(t) {
    const e = typeof t != "string" ? t.viewId : t;
    this.stateTogether.forEach((a, i) => {
      a.delete(e), this.publish(i, "updated", {});
    });
  }
}
l.register("ReactTogetherModel");
export {
  l as default
};
