import { jsx as p } from "react/jsx-runtime";
import { CroquetRoot as f } from "@croquet/react";
import h from "../models/ReactTogetherModel.js";
import { getSessionNameFromUrl as u, getSessionPasswordFromUrl as g } from "../utils/urls.js";
function v({
  children: s,
  sessionParams: o
}) {
  const { appId: i, apiKey: a, sessionIgnoresUrl: c, viewInfo: d } = o;
  let e;
  c || (e = {
    sessionUrl: window.location.origin + window.location.pathname
  });
  const t = new URL(window.location.href), m = u(t), l = g(t), w = o.model || h, r = m || o.name, n = l || o.password;
  return /* @__PURE__ */ p(
    f,
    {
      sessionParams: {
        model: w,
        name: r,
        password: n,
        appId: i,
        apiKey: a,
        options: e,
        viewInfo: d
      },
      deferSession: !r || !n,
      showChildrenWithoutSession: !0,
      children: s
    }
  );
}
export {
  v as default
};
